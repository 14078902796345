#home {
  width: 100%;

  .showreel {
    margin-top: 120px;
    h2 {
      text-align: center;
      margin-bottom: 30px;
      color: #fff;
      font-size: 64px;
      font-weight: bold;
    }
  }

  .swiper-container {
    margin-bottom: 50px;
    .swiper-slide {
      text-align: center;
      iframe {
        width: 100%;
        max-width: 625px;
      }
    }

    .swiper-button-prev {
      left: 50px;
    }
    .swiper-button-next {
      right: 50px;
    }
  }

  section.home__top {
    position: relative;
    margin-bottom: 160px;
    margin-top: 130px;

    &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0;
      height: 300px;
      width: 100%;
      background-image: url('../images/home_pattern.png');
      background-size: cover;
      background-position: center;
    }

    .main__photo {
      position: absolute;
      top: -210px;
      left: 40%;

      @media (max-width: 1025px) {
        top: unset;
        right: -20px;
        width: 70%;
        bottom: -160px;
      }

      @media (max-width: 1700px) {
        left: 50%;
      }
    }

    .content {
      position: relative;
      @extend .container;
      margin: 0 !important;
      max-width: 40%;
      text-align: center;
      z-index: 2;

      h1 {
        position: relative;
        font-family: $fontParagraph;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.1em;
        text-align: right;
        color: $primaryColor;
        text-transform: uppercase;
        color: $blueColor;

        margin: 10px 230px 0 0px;

        &::after {
          // content: '';
          display: block;
          position: absolute;
          top: 20px;
          left: calc(100% + 30px);
          height: 11px;
          width: 10000px;
          background-color: $primaryColor;
        }
      }

      a.btn {
        margin-top: 100px;
      }
    }
  }

  .last-news {
    font-family: $fontParagraph;
    font-style: normal;
    font-weight: bold;
    line-height: 74px;
    font-size: 70px;

    color: #0019ff;
    text-align: left;
    margin-bottom: 4px;
  }

  .line {
    width: 458.22px;
    height: 11px;
    margin-left: 110px;

    background: #0019ff;
  }

  .home__projects {
    display: flex;
    flex-direction: column;

    > h2 {
      text-align: center;
      margin-bottom: 30px;
      color: #fff;
      font-size: 64px;
      font-weight: bold;
    }

    .projects__list {
      display: flex;

      .project {
        display: block;
        height: 0px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }

      @media (max-width: 375px) {
        .project:nth-child(n + 3) {
          display: none;
        }
      }

      @media (min-width: 375px) and (max-width: 640px) {
        .project:nth-child(n + 4) {
          display: none;
        }
      }

      @media (min-width: 640px) and (max-width: 1080px) {
        .project:nth-child(n + 5) {
          display: none;
        }
      }

      @media (min-width: 1080px) {
        .project:nth-child(n + 6) {
          display: none;
        }
      }
    }

    a.btn {
      margin: 35px auto 70px;
    }
  }

  h1 {
    font-family: $fontParagraph;
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    margin: -40px 0 100px;
  }

  section.home__news {
    margin: 100px 50px;
    background-image: url('../images/home_background_pattern.png');
    background-size: contain;
    background-position-y: 380px;
    background-repeat: repeat-y;
    .content {
      @extend .container;
      text-align: center;
      > h2 {
        font-family: $fontParagraph;
        font-size: 72px;
        font-weight: bold;
        color: #fff;
      }

      .news__list {
        margin-top: 240px;
      }

      .portfolio {
        width: 50%;
        flex-basis: 50%;
        margin-left: auto;
        padding-top: 60px;
      }
    }
  }
}

@media all and (max-width: 1025px) {
  #home {
    .swiper-container {
      padding-bottom: 20px;
      .swiper-button-prev {
        top: 90%;
      }
      .swiper-button-next {
        top: 90%;
      }
    }

    section.home__top {
      margin-left: 20px;
      margin-right: 20px;

      &::before {
        top: 90px;
        left: 0;
        right: 0;
        transform: translateX(-50%);
        height: 100%;
        width: 100%;
      }

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .main__logo {
          margin-right: 0;
          width: 100%;
        }

        h1 {
          display: inline-block;
          align-self: flex-end;
          margin: 20px 0 100px;
          padding: 0 20px;
          font-size: 18px;

          &::after {
            top: unset;
            bottom: -20px;
            left: 95px;
          }
        }
      }
    }

    section.home__news {
      margin: 0;
      background: none;
      padding: 60px 20px 20px;

      .content {
        > h2 {
          font-size: 48px;
          line-height: normal;
        }

        .line {
          width: 50%;
          margin-left: -20px;
          margin-top: 20px;
        }

        .news__list {
          margin-top: 150px;
        }

        .portfolio {
          width: 100%;
          flex-basis: 100%;
          margin: 0;
        }
      }
    }
  }
}
