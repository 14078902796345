/**
 *  Variables
 */

$fontTitle: 'Roboto', sans-serif;
$fontParagraph: 'Raleway', sans-serif;

$backgroundColor: #141414;
$primaryColor: #ff7a00;
$blueColor: #0019ff;
