.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  min-height: 50px;
  background: url('../images/button.svg'),
    linear-gradient(
      to right,
      $primaryColor,
      $primaryColor calc(100% - 38px),
      rgba($primaryColor, 0) calc(100% - 37px),
      rgba($primaryColor, 0)
    );
  background-position: center right;
  background-repeat: no-repeat;
  font-family: $fontParagraph;
  font-size: 18px;
  color: #fff;
  letter-spacing: 0.06em;
  text-decoration: none;
  padding: 10px 50px 10px 20px;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.3s;

  i::before {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: white;
    margin-right: 10px;
    line-height: 20px;
  }

  &:hover {
    color: $primaryColor;
    box-shadow: inset 400px 0 0 0 white;

    i::before {
      color: $primaryColor;
    }

    &.btn-inverse {
      color: white;
      box-shadow: inset 400px 0 0 0 black;

      i::before {
        color: white;
      }
    }
  }

  &.xl {
    font-family: $fontParagraph;
    padding: 18px 70px;
    font-size: 28px;
  }
}

.btn-text {
  font-family: $fontParagraph;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  text-decoration: none;
  padding: 10px 15px;

  &:hover {
    text-decoration: none;
  }
}

@media all and (max-width: 1025px){
  .btn {
    display: flex;
    height: 42px;
    padding: 10px 20px 10px 0;
    width: 100%;
  }
}