.grid-item {
  position: relative;
  display: flex;
  z-index: 2;
  width: 50%;
  margin: 30px 0 20px;

  &:hover {
    .app-button {
      @extend .app-button:hover;
    }

    .grid-item__rect {
      filter: grayscale(0) brightness(1) !important;
    }
  }

  h2 {
    position: absolute;
    font-family: $fontParagraph;
    font-size: 24px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    right: 0;
    color: #fff;
  }

  h3 {
    position: absolute;
    font-family: $fontParagraph;
    font-size: 14px;
    color: #fff;
  }

  &:nth-child(odd) {
    margin-left: auto;
    justify-content: flex-end;
    h2 {
      top: -28px;
      left: 270px;
      transform: translateY(-100%);
    }

    h3 {
      top: -66px;
      left: 270px;
      transform: translateY(-100%);
    }

    .thumbnail {
      position: absolute;
      right: 0;
    }

    .grid-item__rect {
      top: -140px;
      right: 276px;
      height: 300px;
      width: 122px;
      background-image: url('../images/grid_item_left_orange.svg');
      transition: filter 0.3s ease-in-out;
      filter: grayscale(1) brightness(0.25);
    }

    .grid-item__button {
      right: -60px;
      bottom: -50px;
    }
  }

  &:nth-child(even) {
    margin-right: auto;
    justify-content: flex-start;
    margin-top: -150px;
    margin-bottom: 200px;

    .grid-item__rect {
      height: 347px;
      width: 122px;
      top: 82px;
      background-image: url('../images/grid_item_right_orange.svg');
      transition: filter 0.3s ease-in-out;
      filter: grayscale(1) brightness(0.25);
    }

    h2 {
      left: 54px;
      bottom: -146px;
      transform: translateY(100%);
    }

    h3 {
      left: 54px;
      bottom: -118px;
      transform: translateY(100%);
    }

    .grid-item__button {
      top: 42px;
      left: 337px;
    }

    .thumbnail {
      margin-left: 92px;
      &::after {
        left: 92px;
      }
    }
  }

  .grid-item__rect {
    position: absolute;
    display: block;
    z-index: -2;
  }

  .grid-item__button {
    position: absolute;
    z-index: 3;
  }

  a:not(.grid-item__button) {
    position: relative;
    display: block;
    width: 100%;
    height: 210px;
    z-index: -1;
  }

  .thumbnail {
    display: block;
    width: 370px;
    height: 292px;
    z-index: 1;
    cursor: pointer;
    background-size: cover;
    background-position: center;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 370px;
      height: 292px;
      background-color: transparent;
      transition: background-color 0.3s ease-in-out;
      z-index: 2;
    }
  }

  .thumbnail:hover,
  .app-button:hover + .thumbnail {
    &::after {
      background-color: rgba($primaryColor, 0.3);
    }
    img {
      filter: grayscale(1);
    }
  }
}

@media all and (max-width: 1025px) {
  .grid-item {
    &:nth-of-type(n) {
      margin: 40px 20px 160px 0;
      width: calc(100% - 20px);

      .thumbnail {
        width: 100%;
        height: 100%;
        margin: 0;

        &::after {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
        }
      }

      h2 {
        top: -35px;
        left: 40px;
        right: 0;
        font-size: 18px;
        transform: none;
        text-align: left;
        bottom: unset;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .grid-item__rect {
        top: -100px;
        left: -20px;
        bottom: 20px;
        width: 120px;
        right: unset;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('../images/grid_item_left_orange.svg');
      }

      h3 {
        top: -60px;
        left: 40px;
        transform: none;
      }

      .grid-item__button {
        top: unset;
        left: unset;
        right: -20px;
        bottom: -20px;
      }

      &:last-of-type {
        margin-bottom: 80px;
      }
    }
  }
}
