section.references {
  margin-bottom: 30px;
  width: 100%;

  h2 {
    font-size: 64px;
    font-weight: 700;
    color: #fff;
  }

  .content {
    @extend .container;
    padding: 50px;
  }

  .white {
    background-color: #fff;
  }

  .references-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 30px;
    width: 100%;
    padding: 30px 0;
    margin-top: 30px;
    grid-row-gap: 30px;

    .reference {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 150px;

      img {
        display: block;
        max-width: 100%;
        object-fit: contain;
      }
    }
  }

  @media (max-width: 1025px) {
    h2 {
      font-size: 48px;
      line-height: normal;
    }

    .references-list {
      grid-template-columns: repeat(2, 1fr);
    }

    .content {
      padding: 20px;
    }
  }
}
