@import url('https://fonts.googleapis.com/css?family=Raleway|Roboto:500,700');

@import './variables';
@import './font';

@import './partials/buttons';
@import './partials/pages';

@import './components/navigation';
@import './components/footer';
@import './components/home';
@import './components/portfolio';
@import './components/news';
@import './components/news-item';
@import './components/grid-item';
@import './components/about';
@import './components/contact';
@import './components/mentions';
@import './components/book';
@import './components/home-news-item';
@import './components/app-button';
@import './components/projects';
@import './components/references';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root,
#app {
  height: 100%;
  width: 100%;
  background-color: $backgroundColor;
  overflow-x: hidden;
  font-family: $fontParagraph;
}

#app {
  position: relative;
}

.container {
  margin: 0 auto;
  max-width: 1200px;
}

.center {
  text-align: center;
  width: 100%;
}

ul,
li {
  list-style-type: none;
  margin: 0;
}

button {
  background: transparent;
  border: 0;
  cursor: pointer;
}

input,
textarea {
  outline: 0;
  border: none;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 18px;
  letter-spacing: 0.05em;
  color: rgba(21, 21, 21, 1);
  background: #eeeeee;
  width: 100%;
  padding: 21px;
  border-bottom: 3px solid transparent;
  transition: border-color 0.3s ease-in-out;
}

.form__row {
  display: flex;
  justify-content: center;
}

.medium-zoom-image--opened {
  z-index: 1000;
}
.medium-zoom--opened .medium-zoom-overlay {
  z-index: 999;
}

@media all and (max-width: 1025px) {
  body.menu--opened {
    overflow-y: hidden;
    #root,
    #app {
      overflow-y: hidden;
    }
  }
}
