@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?k0sqex');
  src:  url('../fonts/icomoon.eot?k0sqex#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?k0sqex') format('truetype'),
    url('../fonts/icomoon.woff?k0sqex') format('woff'),
    url('../fonts/icomoon.svg?k0sqex#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-avion:before {
  content: "\e900";
  color: #0019ff;
}
.icon-bord_btn:before {
  content: "\e901";
  color: #0019ff;
}
.icon-enveloppe:before {
  content: "\e902";
  color: #0019ff;
}
.icon-facebook:before {
  content: "\e903";
  color: #0019ff;
}
.icon-fleche_complete:before {
  content: "\e904";
  color: #0019ff;
}
.icon-fleche_droite:before {
  content: "\e905";
  color: #0019ff;
}
.icon-fleche_gauche:before {
  content: "\e906";
  color: #0019ff;
}
.icon-soundcloud:before {
  content: "\e907";
  color: #0019ff;
}
.icon-telecharger:before {
  content: "\e908";
  color: #0019ff;
}
