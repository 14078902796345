#portfolio {
  background-image: url('../images/pattern_fond.png');
  background-size: contain;
  background-position-y: 480px;
  background-repeat: repeat-y;
  width: 100%;
  .header {
    display: block;
    height: 480px;
    width: 100%;
    background-image: url('../images/portfolio_header_black.png');
    background-position: center;
    background-size: cover;

    &.no-margin {
      margin-bottom: 0;
    }

    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 100%;
      z-index: 2;
      @extend .container;
      h1 {
        position: relative;
        font-family: $fontParagraph;
        font-size: 70px;
        font-weight: bold;
        color: #fff;

        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: -15px;
          left: 90px;
          height: 11px;
          width: 255px;
          background-color: $primaryColor;
        }
      }

      .tags-menu {
        display: none;
      }

      .tags {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        .tag {
          width: calc(25% - 60px);
          flex-basis: calc(25% - 60px);
          color: #fff;
          border: 1px solid #fff;
          margin: 15px 30px;
          padding: 10px;
          text-align: center;
          font-family: $fontParagraph;
          font-weight: 500;
          font-size: 18px;
          cursor: pointer;
          transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out,
            border-color 0.3s ease-in-out;

          &.tag--selected,
          &:hover {
            background-color: $blueColor;
            border-color: $blueColor;
            color: white;
          }
        }
      }
    }
  }
  .projects {
    display: flex;
    flex-direction: column;

    .no-project {
      margin: 50px 0 0;
      font-weight: bold;
      color: $primaryColor;
      text-align: center;
      font-size: 24px;
    }

    div:last-child:nth-child(odd) {
      &.aligned {
        margin-top: 0;
        width: 50%;
        flex-basis: 50%;
        margin-left: auto;
        text-align: left;
      }
    }

    div:last-child:nth-child(even) {
      &.aligned {
        margin-top: 0;
        width: 50%;
        flex-basis: 50%;
        margin-right: auto;
        text-align: right;
      }
    }

    .list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .contact {
    margin-bottom: 150px;
    padding-top: 60px;
  }
}

@media all and (max-width: 1025px) {
  #portfolio {
    background: none;
    .header {
      height: auto;
      margin-bottom: 80px;
      .content {
        padding: 20px;
        h1 {
          font-size: 48px;
          margin-bottom: 40px;

          &::after {
            left: -20px;
            width: 215px;
          }
        }

        .tags-menu {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          background-color: $primaryColor;
          width: 100%;
          color: white;
          padding: 15px 25px;
          font-weight: bold;

          &::after {
            content: '';
            position: absolute;
            display: block;
            top: 10px;
            right: 25px;
            height: 15px;
            width: 15px;
            border-bottom: 2px solid white;
            border-left: 2px solid white;
            transform: rotate(-45deg);
            transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
          }

          &.opened {
            &::after {
              top: 20px;
              transform: rotate(135deg);
            }

            + .tags {
              max-height: 1000px;
            }
          }
        }

        .tags {
          padding: 0 15px 0;
          background-color: #fff;
          align-items: stretch;
          overflow: hidden;
          max-height: 0;
          transition: max-height 0.6s ease-in-out;

          .tag {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            margin: 0 0 20px;
            width: calc(50% - 8px);
            flex-basis: calc(50% - 8px);
            color: $primaryColor;
            border-color: $primaryColor;

            &:first-of-type {
              margin-top: 20px;
            }

            &:nth-of-type(even) {
              margin-right: 15px;
            }

            &:first-of-type {
              width: 100%;
              flex-basis: 100%;
            }
          }
        }
      }
    }

    .projects {
      padding: 20px;
      margin-bottom: -40px !important;

      .no-project {
        margin: 10px 0 40px;
      }
    }

    .contact {
      margin: 0 0 100px;
      width: 100% !important;
      flex-basis: 100% !important;
    }
  }
}
