.contact__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .contact__socials {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 79px;

    .contact__social {
      margin: 0 22px;
    }
  }

  .contact__background {
    position: absolute;
    left: 0;
  }

  .contact__header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form__wrapper {
    max-width: 876px;

    .input {
      margin: 19px;
      position: relative;
      display: flex;
      width: 100%;

      label {
        position: absolute;
        font-family: Raleway;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-size: 18px;
        letter-spacing: 0.05em;

        color: rgba(21, 21, 21, 0.4);
        transform: translateY(20px);
        left: 20px;
        pointer-events: none;

        transition: transform 0.3s, color 0.3s;
      }
    }

    .input.focus {
      label {
        transform: translateY(-25px) translateX(-26px) scale(0.77777777777);
        color: black;
      }
    }

    textarea {
      min-height: 230px;
    }

    input:focus,
    textarea:focus {
      border-color: #0019ff;
    }

    input,
    textarea {
      border-bottom: 3px solid transparent;
      transition: border-color 0.3s ease-in-out;
    }

    input[type="submit"] {
      border: 1px solid #0019ff;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 5px;
      font-family: Raleway;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      font-size: 20px;
      text-align: center;
      letter-spacing: 0.05em;
      -moz-appearance: none;
      -webkit-appearance: none;
      color: #0019ff;
      width: auto;
      padding: 12px 30px;
      background: transparent;
      transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
      cursor: pointer;
    }

    input[type="submit"]:hover {
      background-color: #0019ff;
      color: #fff;
    }
  }

  .contact__subtitle {
    font-family: Raleway;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    font-size: 28px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #0019ff;
  }

  .contact__separator {
    margin-top: 8px;
    margin-bottom: 50px;
    width: 30px;
    height: 5px;

    background: #0019ff;
  }
}
