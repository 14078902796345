#news-item {
  @extend .container;
  .date {
    font-family: $fontTitle;
    font-size: 24px;
    margin-bottom: 40px;
  }

  h2 {
    font-family: $fontTitle;
    font-size: 18px;
    margin-bottom: 20px;
  }

  p,
  .credits {
    font-family: $fontTitle;
    font-size: 14px;
  }

  p {
    margin-bottom: 50px;
  }

  .credits {
    margin-bottom: 80px;
  }

  img {
    width: 100%;
    margin-bottom: 25px;
  }
}
