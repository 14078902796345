#news {
  .header {
    display: block;
    width: 100%;
    background-image: url('../images/portfolio_header_black.png');
    background-position: center;
    background-size: cover;
    padding: 90px 0;
    margin-bottom: 170px;

    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      z-index: 2;
      @extend .container;
      h1 {
        position: relative;
        font-family: $fontParagraph;
        font-size: 70px;
        font-weight: bold;
        color: #fff;
        margin: 0;

        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: -15px;
          left: 90px;
          height: 11px;
          width: 255px;
          background-color: $primaryColor;
        }
      }
    }
  }

  main {
    @extend .container;
  }
}

@media all and (max-width: 1025px) {
  #news .header {
    height: auto;
    padding: 0;
    margin-bottom: 0;

    .content {
      padding: 20px;
      h1 {
        font-size: 48px;
        margin-bottom: 40px;

        &::after {
          left: -20px;
          width: 215px;
        }
      }
    }
  }
}
