section.home__book {
  position: relative;
  display: block;
  height: 210px;
  width: 90%;
  margin-left: 10%;
  background-image: url('../images/console.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: visible;
  //mix-blend-mode: multiply;
  //filter: saturate(2);

  &::before {
    content: '';
    position: absolute;
    top: 70px;
    left: -10000px;
    height: 8px;
    width: 10240px;
    background-color: $primaryColor;
    z-index: 10;
  }

  .content {
    position: absolute;
    top: 50%;
    left: 270px;
    transform: translate3d(0, -50%, 0);
    text-align: left;
    color: #fff;
    z-index: 3;

    h3 {
      font-family: $fontParagraph;
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 15px;
      letter-spacing: 0.05em;

      span {
        font-size: 24px;
      }
    }

    .btn-text {
      position: relative;
      display: inline-flex;
      align-items: flex-end;
      img {
        margin-right: 12px;
        transition: transform 0.3s ease-in-out;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 55px;
        bottom: -1px;
        background-color: #fff;
        height: 1px;
        transition: all 0.3s ease-in-out;
      }
      color: #fff;

      &:hover {
        img {
          transform: scale(1.35);
        }

        &::after {
          width: calc(100% - 70px);
        }
      }
    }
  }
}

@media all and (max-width: 1025px) {
  section.home__book {
    height: 300px;
    margin: 0;
    width: 100%;

    &::before {
      top: 180px;
    }

    .content {
      left: 20px;
      right: 20px;

      h3 {
        font-size: 30px;

        span {
          display: block;
          font-size: 16px;
          padding-top: 30px;
        }
      }

      .btn-text {
        margin-top: 60px;
        justify-content: center;
      }
    }
  }
}
