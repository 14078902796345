.project {
  position: relative;
  display: block;
  height: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-basis: 25%;

  &::after,
  h2,
  h3 {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($primaryColor, 0.5);
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
  }

  h3 {
    font-weight: 700;
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
  }

  .titles {
    position: absolute;
    bottom: 30px;
    left: 25px;
    right: 25px;
    z-index: 2;
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }

  @media (min-width: 768px) {
    &:hover {
      &::after,
      h2,
      h3 {
        opacity: 1;
      }
    }
  }
}

.project-list {
  @media (max-width: 375px) {
    .project {
      padding-top: 50%;
      flex-basis: 50%;
    }
  }

  @media (min-width: 375px) and (max-width: 768px) {
    .project {
      padding-top: 33%;
      flex-basis: 33%;
    }
  }

  @media (min-width: 768px) and (max-width: 1080px) {
    .project {
      padding-top: 25%;
      flex-basis: 25%;
    }
  }

  @media (min-width: 1080px) {
    .project {
      padding-top: 20%;
      flex-basis: 20%;
    }
  }
}
