.app-button {
  position: relative;
  font-family: $fontParagraph;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 18px;
  letter-spacing: 0.05em;
  background: #232323;
  color: #fff;
  padding: 12px 40px 12px 20px;
  border-bottom: solid $primaryColor 2px;
  text-decoration: none;
  transition: box-shadow 0.3s ease-in-out, color 0.3s ease-in-out;

  &:hover {
    color: #191919;
    box-shadow: inset 0 -60px 0 0 $primaryColor;

    &::after {
      border-color: #191919;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 14px;
    right: 7px;
    display: block;
    width: 14px;
    height: 14px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transition: border-color 0.3s ease-in-out;
    transform: rotate(-45deg) translate(-50%, -50%);
  }
}
