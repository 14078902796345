#about {
  width: 100%;
  background-image: url('../images/pattern_fond.png');
  background-size: contain;
  background-repeat: repeat-y;
  background-position-y: 260px;
  .header {
    display: block;
    width: 100%;
    background-image: url('../images/portfolio_header_black.png');
    background-position: center;
    background-size: cover;
    padding: 90px 0;
    margin-bottom: 170px;

    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      z-index: 2;
      @extend .container;
      h1 {
        position: relative;
        font-family: $fontParagraph;
        font-size: 70px;
        font-weight: bold;
        color: #fff;
        margin: 0;

        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: -15px;
          left: 90px;
          height: 11px;
          width: 255px;
          background-color: $primaryColor;
        }
      }
    }
  }
  h2 {
    font-family: $fontParagraph;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
    color: $primaryColor;
  }
  p {
    font-family: $fontParagraph;
    font-size: 16px;
    color: #fff;
  }

  section {
    display: flex;
    padding: 40px 0 80px;

    .column {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      &:first-child {
        flex: 0 0 40%;
        padding-right: 60px;
      }

      &:last-child {
        flex: 0 0 60%;
        padding-left: 60px;
      }

      img {
        width: 460px;
      }

      .info {
        position: relative;
        z-index: 3;
      }

      .photo__effect {
        position: relative;
        z-index: 2;

        &::before {
          content: '';
          position: absolute;
          top: -100px;
          right: -200px;
          width: 436px;
          height: 288px;
          background-image: url('../images/about_effect.svg');
          z-index: -1;
        }
      }
    }
  }

  .jumbotron {
    position: relative;
    height: 500px;
    width: 70%;
    background-image: url('../images/about_photo_5.png');
    background-size: cover;
    margin: 40px 0 200px auto;

    .jumbo {
      position: absolute;
      left: -250px;
      bottom: -80px;
      width: 330px;
      z-index: 3;
    }
  }

  .contact {
    margin-top: 200px;
  }
}

@media all and (max-width: 1025px) {
  #about {
    background: none;
    .header {
      padding: 40px 20px 60px;
      margin-bottom: 60px;
      .content {
        h1 {
          font-size: 48px;

          &::after {
            left: -20px;
            width: 215px;
          }
        }
      }
    }

    section {
      padding: 20px;
      flex-direction: column;
      margin-bottom: 30px;

      .column:nth-child(n) {
        padding: 0;
        flex: 0 0 100%;
        width: 100%;

        img {
          width: 100%;
        }

        h2 {
          margin-bottom: 10px;
        }

        p {
          font-size: 14px;
        }

        .photo__effect::before {
          top: -40px;
          left: unset;
          right: -40px;
          width: 240px;
          height: 160px;
          background-size: cover;
        }

        .info {
          margin-top: 50px;
        }
      }
    }

    .jumbotron {
      height: 62.5vw;
      width: 90%;
      margin: 0 0 200px auto;

      .jumbo {
        left: -11%;
        bottom: -145px;
        width: 75%;
      }
    }

    .contact {
      margin: 0 20px;
      width: calc(100% - 40px);
    }
  }
}
