.home-news__item {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.home-news__item.reversed {
  flex-direction: column-reverse;

  .home-news__rect {
    margin-top: -117px;
  }

  .home-news__button {
    top: auto;
    bottom: 30px;
  }
}

.home-news__texts {
  z-index: 1;
}

.home-news__button {
  position: absolute;
  top: 30px;
  z-index: 2;
  right: -33px;
}

.home-news__date,
.home-news__title,
.home-new__picture {
  z-index: 1;
}

#home section.home__news h2.home-news__date {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 14px;
  letter-spacing: 0.05em;

  color: #0019ff;
  text-align: left;
  margin-bottom: 9px;
}

#home section.home__news h1.home-news__title {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 24px;
  letter-spacing: 0.05em;

  color: #151515;
  max-width: 307px;
  margin: 0;
  text-align: left;
}

.home-new__picture {
  margin-left: 36px;
  margin-bottom: 18px;
}

.home-news__rect {
  background: url("../images/news_rect.png");
  width: 122px;
  height: 347px;
  position: absolute;
  z-index: 0;
  margin-left: -50px;
  margin-top: +117px;
}
