footer {
  position: relative;
  @extend .container;
  padding: 60px 0 30px;

  &::before {
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    top: 80px;
    left: -10000px;
    right: -10000px;
    background-color: #ffffff;
  }

  nav {
    position: relative;
  }

  nav ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .mentions {
      font-family: $fontParagraph;
      font-size: 12px;
      font-weight: 500;
      margin-right: auto;
      padding: 5px 20px;
      color: #fff;
      background-color: $backgroundColor;

      span {
        margin-left: 15px;
        padding-right: 20px;
      }
    }

    li {
      &:nth-child(2) a {
        padding-left: 40px;
      }

      &:last-of-type a {
        padding-right: 40px;
      }
    }

    li a {
      padding: 5px 20px;
      background-color: $backgroundColor;
      color: #fff;
      transition: all 0.3s ease-in-out;
      text-decoration: none;

      &:hover {
        color: $primaryColor;
      }

      img {
        transition: filter 0.3s ease-in-out;
        filter: hue-rotate(0deg) saturate(1) brightness(100%);
        &.facebook {
          width: 19px;
          height: 35px;
        }
        &.soundcloud {
          width: 50px;
          height: 22px;
        }

        &:hover {
          filter: hue-rotate(0deg) saturate(0) brightness(30%);
        }
      }
    }
  }
}

@media all and (max-width: 1025px) {
  footer {
    nav {
      ul {
        flex-wrap: wrap;
        justify-content: center;
        .mentions {
          margin-top: 20px;
          text-align: center;
          width: 100%;
          order: 20;
        }
      }
    }
  }
}
