header.page__header {
  position: relative;
  @extend .container;
  padding: 30px 0 60px;

  &::before {
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    top: 56px;
    left: -10000px;
    right: -10000px;
    background-color: #fff;
  }

  nav {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    position: relative;
    z-index: 1;
  }

  #logo {
    margin-right: auto;
    padding: 5px 13px;
    background-color: $backgroundColor;
  }

  nav {
    .burger {
      display: none;
    }
    .menu {
      .close {
        display: none;
      }
    }

    .languages-switcher {
      position: absolute;
      top: -18px;
      right: 0;

      ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;

        li {
          display: block;
          padding: 5px 13px;
          font-size: 14px;
          color: #8b8b8b;
          font-weight: 500;
          text-transform: uppercase;
          cursor: pointer;

          &.selected {
            background-color: #fff;
          }
        }
      }

      @media (max-width: 1025px) {
        display: none;
      }
    }

    ul.networks {
      display: none !important;
    }
    ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      list-style: none;

      li:not(#logo) a {
        position: relative;
        font-family: $fontParagraph;
        font-size: 18px;
        padding: 5px 13px;
        color: #fff;
        text-decoration: none;
        font-weight: 600;
        background-color: $backgroundColor;
        transition: color 0.3s ease-in-out;

        &::after {
          content: '';
          display: block;
          position: absolute;
          left: 13px;
          right: 100%;
          bottom: 0;
          height: 2px;
          background-color: $primaryColor;
          transition: right 0.2s ease-in-out;
        }

        &:hover,
        &.selected {
          color: $primaryColor;
          &::after {
            right: 13px;
          }
        }
      }
    }
  }
}

#contact {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate(100%);
  transition: transform 0.6s ease-in-out;
  z-index: 100;

  &.opened {
    transform: translate(0);
    .contact__overlay {
      visibility: visible;
      opacity: 1;
    }
  }
}

.contact__overlay {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: -100%;
  right: -100%;
  bottom: 0;
  background-color: rgba($primaryColor, 0.6);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
}

.contact__window {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  min-width: 720px;
  width: 50%;
  background: white;
  padding: 30px 125px 60px;
  z-index: 200;
  overflow: hidden;

  .back {
    display: none;
  }

  .close {
    display: block;
    position: relative;
    font-family: $fontParagraph;
    font-size: 18px;
    font-weight: bold;
    margin-left: auto;
    text-align: right;
    padding: 10px 20px 10px 10px;
    cursor: pointer;

    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 15px;
      right: 0;
      display: block;
      height: 13px;
      width: 1px;
      background-color: #000;
      transform: translateY(-50%);
    }

    &::after {
      transform: rotate(45deg);
    }

    &::before {
      transform: rotate(-45deg);
    }
  }

  h1 {
    position: relative;
    font-family: $fontParagraph;
    font-size: 70px;
    font-weight: bold;
    color: $primaryColor;
    margin-top: 50px;
    margin-bottom: 75px;

    &::after {
      content: '';
      position: absolute;
      bottom: -15px;
      left: 0;
      height: 11px;
      width: calc(50% + 15px);
      transform: translateX(-50%);
      background-color: $primaryColor;
    }
  }

  .contact-message {
    display: none;
    font-size: 18px;
    font-weight: 500;
    font-style: italic;
    margin: 0 0 50px;
  }

  .success {
    color: #2aa100;
  }

  .error {
    color: #bd0000;
  }

  .info {
    color: #0c5460;
  }

  form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    .input {
      position: relative;
      flex-basis: 100%;
      margin-bottom: 30px;

      &.input--50 {
        flex-basis: calc(50% - 15px);
        width: calc(50% - 15px);

        &:nth-of-type(odd) {
          margin-right: 15px;
        }
        &:nth-of-type(even) {
          margin-left: 15px;
        }
      }

      label {
        position: absolute;
        top: 19px;
        left: 22px;
        color: rgba(#151515, 0.4);
        font-family: $fontParagraph;
        font-size: 18px;
        font-weight: 500;
        transition: all 0.3s ease-in-out;
      }

      input,
      textarea {
        position: relative;
        border: none;
        outline: none;
        box-shadow: none;
        resize: none;
        &:active,
        &:focus,
        &:valid {
          + label {
            top: -4px;
            left: 0;
            transform: translateY(-100%);
            font-size: 14px;
            color: #151515;
          }
        }

        &.error {
          border: 1px solid #bd0000;
        }
      }
      &::after {
        content: '';
        display: none;
        position: absolute;
        left: 15px;
        right: 15px;
        bottom: 7px;
        height: 1px;
        width: calc(100% - 30px);
        background-color: $primaryColor;
      }

      &.textarea::after {
        bottom: 12px;
      }

      &:focus-within::after {
        display: block;
      }

      input {
        padding: 18px;
      }
    }

    input[type='submit'] {
      position: relative;
      width: 250px;
      border-bottom: none;
    }

    .btn.send {
      &:hover {
        color: white;
        box-shadow: inset 400px 0 0 0 black;
      }
    }
  }

  .networks {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 30px;

    li {
      padding: 5px 20px;

      img {
        transition: filter 0.3s ease-in-out;
        &.facebook {
          width: 19px;
          height: 35px;
        }
        &.soundcloud {
          width: 50px;
          height: 22px;
        }

        &:hover {
          filter: hue-rotate(0deg) saturate(0) brightness(30%);
        }
      }
    }
  }
}

@media all and (max-width: 1025px) {
  header.page__header nav {
    z-index: 10;

    .burger {
      display: block;
      cursor: pointer;
      padding: 20px;
      background-color: $backgroundColor;
      .wrapper {
        position: relative;
        display: block;
        height: 2px;
        width: 30px;
        background-color: white;

        &::before,
        &::after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          height: 2px;
          width: 30px;
          background-color: white;
        }

        &::before {
          top: -8px;
        }

        &::after {
          top: 8px;
        }
      }
    }

    .menu {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 30px;
      height: 100%;
      width: 100%;
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out;
      background-color: #fff;

      &.menu__opened {
        transform: translateX(0);
      }

      .close {
        display: block;
        position: relative;
        font-family: $fontParagraph;
        font-size: 14px;
        font-weight: bold;
        text-align: right;
        padding-right: 25px;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: -5px;
          right: 0;
          height: 25px;
          width: 1px;
          background-color: #000;
        }

        &::before {
          transform: rotate(-45deg);
        }

        &::after {
          transform: rotate(45deg);
        }
      }
    }
    ul:not(.networks) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;

      li:not(#logo) {
        padding: 20px;
        a {
          color: $primaryColor;
          background-color: transparent;
          font-weight: bold;
          text-transform: uppercase;
          &::after {
            display: none;
          }
        }
      }
    }

    ul.networks {
      display: flex !important;
      position: absolute;
      left: 50%;
      bottom: 20px;
      transform: translate(-50%, -50%);
      flex-direction: row;
      align-content: center;
      justify-content: center;

      li:not(#logo) {
        a {
          background: none;
        }
      }
    }
  }
  #contact {
    transition: transform 0.3s ease-in-out;
    .contact__overlay {
      display: none !important;
    }
    .contact__window {
      min-width: 0;
      width: 100%;
      padding: 20px;
      overflow-y: scroll;

      .navigation {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0 30px;
      }

      .back {
        position: relative;
        display: block;
        font-weight: bold;
        font-size: 18px;
        text-transform: uppercase;
        color: $primaryColor;
        padding-left: 20px;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 2px;
          left: 0;
          height: 15px;
          width: 15px;
          border-left: 2px solid $primaryColor;
          border-bottom: 2px solid $primaryColor;
          transform: rotate(45deg);
        }
      }

      .close {
        font-size: 14px;
        padding-right: 30px;
        &::before,
        &::after {
          top: 5px;
          right: 10px;
          height: 25px;
        }
      }

      h1 {
        display: none;
      }

      .input,
      .input.input--50 {
        margin: 0 0 25px;
        width: 100%;
        flex-basis: 100%;
      }

      .center .btn {
        margin: 0 0 30px;
        width: 100%;
      }

      .networks {
        display: none;
      }
    }
  }
}
